import {_delete, _get, _post, _put} from "@api/services/httpService";

export const BLOCK_PRICE_CATEGORY_URI = '/clubs/playgrounds/timetables/blocks/prices/categories';

export const getBlockPriceCategories = (activityType, clubId) => _get(BLOCK_PRICE_CATEGORY_URI + '?activityType=' + activityType + '&club.id=' + clubId +'&itemsPerPage=120');

export const getBlockPriceCategory = (id) => _get(BLOCK_PRICE_CATEGORY_URI + '/' + id);

export const getAllBlockPriceCategories = (itemsPerPage = 10, page = 1, clubId = null, search='') => {
  const url = clubId ? BLOCK_PRICE_CATEGORY_URI + '?club.id=' + clubId + '&search=' + search + '&itemsPerPage=' + itemsPerPage + '&page=' + page : BLOCK_PRICE_CATEGORY_URI + '?itemsPerPage=' + itemsPerPage + '&search=' + search + '&page=' + page;

  return _get(url);
}

export const getClubForms = (clubId) => _get('/clubs/forms' + '?club.id=' +clubId + '&itemsPerPage=' + 120 + '&page=1');


export const postBlockPriceCategory = (blockPriceCategory) => _post(BLOCK_PRICE_CATEGORY_URI, blockPriceCategory);

export const putBlockPriceCategory = (blockPriceCategory) => _put(BLOCK_PRICE_CATEGORY_URI + '/' + blockPriceCategory.id, blockPriceCategory);

export const deleteBlockPriceCategory = (id) => _delete(BLOCK_PRICE_CATEGORY_URI + '/' + id);
