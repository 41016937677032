import {_delete, _get, _post, _put} from "@api/services/httpService";

const URI = "/clubs/playgrounds/timetables";

export const postTimetable = (timetable) => _post(URI, timetable);

export const putTimetable = (timetable) => _put(URI + '/' + timetable.id, timetable);

export const deleteTimetable = (id) => _delete(URI + '/' + id);

export const getTimetables = (itemsPerPage = 10, page = 1, clubId = null, search='', type = '') => {
  const url = clubId ? URI + '?club.id=' + clubId + type + '&search='+ search +'&itemsPerPage=' + itemsPerPage + '&page=' + page : URI + '?itemsPerPage=' + itemsPerPage +'&search='+ search+ '&page=' + page + type ;

  return _get(url);
}

export const getPlaygroundTimetables = (itemsPerPage, page, playgroundId) => _get(URI + '?club.id=' + clubId + '&itemsPerPage=' + itemsPerPage + '&page=' + page + '&playgrounds.id=' + playgroundId);
