<template>
  <div>
    <b-row>
      <b-col cols="12">
        <d-select-input-search
          :options="clubs"
          :place-holder="$t('admin.duplicate.search-club-model')"
          class-name="bg-white mt-2 mb-3 w-75"
          icon="fa fa-search"
          @on:keydown="searchClubModel"
          @on:selected="onSelect"
        />
      </b-col>
      <b-col cols="4">
        <d-button
          :text="check ? 'admin.duplicate.select-all' : 'admin.duplicate.unselect-all'"
          class="d-btn-sm d-btn btn d-btn-success font-text-title mt-1"
          @on:button-click="onCheckAll"
        />
      </b-col>
      <b-col align="right">
        <div class="has-search">
          <span class="fa fa-search form-control-feedback"></span>
          <input
            v-model="filter"
            class="form-control"
            type="text"
            :placeholder="$t('components.doinsport.table.search')"
          >
        </div>
      </b-col>
    </b-row>
    <d-table
      identifier="calendar"
      :tablefields="fields"
      :items="items"
      :per-page="itemsPerPage"
      :totalRows="totalRows"
      :is-busy="isBusy"
      :per-page-enabled="true"
      :tr-cursor-pointer="false"
      @per-page:update="onPerPageUpdate"
      @on:data-table-btn:checkbox="onDataTableCheck"
      @current-page:update="onPageUpdate"
    >
    </d-table>
    <div class="d-flex justify-content-end">
      <d-button
        :text="'admin.duplicate.proceed-to-edit'"
        class="d-btn-sm d-btn btn d-btn-success font-text-title mt-3"
        @on:button-click="onProceed"
      />
    </div>
  </div>
</template>
<script>
import {getClubs} from "@api/services/club/club.api";
import {getTimetables} from "@api/services/timetable/timetable.api";
import {getAllBlockPriceCategories} from "@api/services/timetable/blocks/price/category/category.api";
import {cloneData} from "@/utils/form";

export default {
  data: () => ({
    items: [],
    clubs: [],
    filter: '',
    selectedClubId: '',
    isBusy: false,
    check: false,
    timer: null,
    itemsPerPage: 10,
    totalRows: 1,
    currentPage: 1,
    tabs: [
      {name: 'admin.duplicate.menus.permanent', active: true, id: 'permanent'},
      {name: 'admin.duplicate.menus.holiday', active: false, id: 'holiday'},
    ]
  }),
  props: {
    reload: {
      type: Boolean,
      default: false
    },
    filter: {
      type: String,
      default: ''
    },
    isArchived: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    reload: function () {
      this.loadBlockPriceCategories(this.itemsPerPage, this.currentPage, this.filter);
    },
    filter: function () {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.loadBlockPriceCategories(this.itemsPerPage, this.currentPage, this.filter);
      }, 800);
    }
  },
  computed: {
    alignCellsToLeft() {
      return this.$store.getters['layout/getInnerWidth'] >= 991 ? 'text-center' : 'text-left';
    },
    clubList() {
      return this.$store.getters['club/getClubList']
    },
    checkedCalendar() {
      return this.$store.getters['calendar/getSelectedCalendarModel']
    },
    checkedCalendarList() {
      return this.$store.getters['calendar/getCheckedCalendars'];
    },
    fields() {
      return [
        {
          key: "name",
          label: this.$t('admin.duplicate.shop-category.title'),
          sortable: true,
        },
        {
          key: "club.name",
          label: this.$t('admin.duplicate.shop-category.club'),
        },
        {
          key: "actions",
          label: this.$t("general.actions.actions"),
        },
      ];
    },
    getCheckedCategoryList() {
      return this.$store.getters['calendar/getCheckedCalendars'];
    },
    getSelectedCalendar() {
      return this.$store.getters['calendar/getSelectedCalendarModel'];
    },
  },
  methods: {
    searchClubModel($event) {
      console.log('event', $event)
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.loadClubs($event)
      }, 1900);
    },
    onSelect($event) {
      this.$store.commit('calendar/setSelectedCalendarModel', null);

      if ($event === null) {
        this.selectedClubId = '';
        this.loadBlockPriceCategories(this.itemsPerPage, this.currentPage, this.filter);
      } else {
        this.selectedClubId = $event.item.id;

        this.loadBlockPriceCategories(this.itemsPerPage, this.currentPage, this.filter);
      }
    },
    onProceed() {
      const calendarType = this.tabs[0].active ? this.tabs[0].id : this.tabs[1].id;

      this.$nextTick(() => {
        this.$router.push({name: 'duplicate_block_price_category'});
      });
    },
    onDataTableCheck($event) {
      if ($event.checked) {
        this.$store.commit('blockPriceCategory/addToBlockPriceCategoryList', $event)
      } else {
        this.$store.commit('blockPriceCategory/removeFromBlockPriceCategoryCheckedList', $event)
      }
    },
    onCheckAll() {
      this.check = !this.check;

      for (const item of this.items) {
        item.checked = this.check;

        if (item.checked) {
          this.$store.commit('blockPriceCategory/addToBlockPriceCategoryList', item)
        } else {
          this.$store.commit('blockPriceCategory/removeFromBlockPriceCategoryCheckedList', item)
        }
      }

    },
    onPageUpdate(page) {
      this.loadBlockPriceCategories(this.itemsPerPage, page, this.filter);
    },
    onPerPageUpdate(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.currentPage = 1;
      this.loadBlockPriceCategories(itemsPerPage, this.currentPage, this.filter);
    },
    loadBlockPriceCategories(itemsPerPage, currentPage, filter) {
      this.items = [];
      this.isBusy = true;
      const filterTag = 'asc';

      getAllBlockPriceCategories(itemsPerPage, currentPage, this.selectedClubId, filter)
        .then((response) => {
          this.totalRows = response.data['hydra:totalItems'];

          for (const item of response.data['hydra:member']) {
            item.club ? item.actions = ['checkbox'] : item.actions = [];
            item.checked = false;

            this.items.push(item);
          }
        })
        .finally(() => {
          this.isBusy = false;
        })
      ;
    },
    loadClubs(filter = '') {
      getClubs(1, 30, filter)
        .then((response) => {
          this.clubs = [];

          this.$store.commit('club/setClubs', response.data['hydra:member']);

          for (const club of response.data['hydra:member']) {
            this.clubs.push({
              label: club.name,
              id: club.id,
              item: club,
              iri: club['@id'],
            });
          }
        })
      ;
    },
  },
  created() {
    this.clubs = cloneData(this.clubList);
    this.loadBlockPriceCategories(this.itemsPerPage, this.currentPage, this.filter);
  }
}
</script>
<style scoped lang="scss">
@import "@lazy/_b-card.scss";

/deep/ .card .card-body {
  padding-left: 25px;
  padding-right: 25px;
}

/deep/ .page-item.active .page-link {
  z-index: 0;
  color: #fff;
  background-color: #092772;
  border-color: #092772;
}
</style>
